import { Controller } from "@hotwired/stimulus";
import throttle from "lodash.throttle"; // Or implement your own throttle

export default class extends Controller {
    static targets = ["status"];
    static values = { timeout: Number }; // Timeout in minutes

    connect() {
        this.currentState = "offline"; // Initial state
        this.startEventListeners(); // Attach event listeners
        this.resetTimers(); // Initialize timers
    }

    disconnect() {
        this.clearTimers(); // Clean up timers
        this.stopEventListeners(); // Remove event listeners
    }

    startEventListeners() {
        this.handleUserActivity = throttle(() => {
            this.setOnline();
        }, this.timeoutValue * 60 * 1000);

        document.addEventListener("mousemove", this.handleUserActivity);
        document.addEventListener("touchstart", this.handleUserActivity);
    }

    stopEventListeners() {
        document.removeEventListener("mousemove", this.handleUserActivity);
        document.removeEventListener("touchstart", this.handleUserActivity);
    }

    resetTimers() {
        this.clearTimers();

        this.awayTimer = setTimeout(() => {
            this.setAway();
        }, this.timeoutValue * 60 * 1000);

        this.offlineTimer = setTimeout(() => {
            this.setOffline();
        }, this.timeoutValue * 60 * 1000 * 2);

        this.setOnline(); // Start as online
    }

    clearTimers() {
        clearTimeout(this.awayTimer);
        clearTimeout(this.offlineTimer);
    }

    setOnline() {
        if (this.currentState !== "online") {
            this.currentState = "online";
            window.dispatchEvent(new CustomEvent("userOnline"));
            this.resetTimers(); // Reset timers on activity
        }
    }

    setAway() {
        if (this.currentState !== "away") {
            this.currentState = "away";
            window.dispatchEvent(new CustomEvent("userAway"));
        }
    }

    setOffline() {
        if (this.currentState !== "offline") {
            this.currentState = "offline";
            window.dispatchEvent(new CustomEvent("userOffline"));
        }
    }
}
