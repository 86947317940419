import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static values = {
        currentEmail: String
    }
    connect() {
        this.setEmail()
    }


    setEmail() {
        if (document.getElementById('no_email')!=undefined) {
            if (document.getElementById('no_email').checked == true) {

                document.getElementById('user_email').value = this.element.dataset.slug+"@ukcil.com";
                document.getElementById('user_email').readOnly = true;

                document.getElementById('no_email_message').hidden= false;
                document.getElementById('email_message').hidden= true;
                document.getElementById('user_email').classList.add('bg-gray-100');
                document.getElementById('user_email').classList.add('cursor-not-allowed');
            } else {

                document.getElementById('user_email').value = this.currentEmailValue;
                document.getElementById('user_email').readOnly = false;
                document.getElementById('no_email_message').hidden= true;
                document.getElementById('email_message').hidden= false;
                document.getElementById('user_email').classList.remove('bg-gray-100');
                document.getElementById('user_email').classList.remove('cursor-not-allowed');
            }

        }


    }
}