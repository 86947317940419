import {Tabs} from "tailwindcss-stimulus-components";

export default class extends Tabs {

    static targets = ['tab', 'panel', 'submenu']


    initialize() {
        this.activeTabClasses = this.data.get('activeTab').split(' ');
        this.inactiveTabClasses = this.data.get('inactiveTab').split(' ');


        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const pTab = urlParams.get('tab')

        if (pTab !== null) {
            this.tabTargets.forEach((tab, index) => {
                if (tab.dataset.tabsId === pTab) {
                    this.index = index
                }
            })

        }
        super.initialize();
    }


}