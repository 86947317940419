import { Controller } from "@hotwired/stimulus";
import {enter, leave} from 'el-transition';


export default class extends Controller {
    static targets = ["modal", "button", 'wrapper','url', "panel"]

    connect() {
        // console.log('connected')
        this.url = this.data.get('url')
       // console.log(this.data.get('url'))
    }

    toggleModal() {
        if(this.modalTarget.classList.contains('hidden')) {
            this.getContent(this.url)
            enter(this.modalTarget)


        } else {
            leave(this.modalTarget)
        }

    }

    getContent(url) {
        fetch(url).then(response => {
            if (response.ok) {
                return response.text()
            }
        })
            .then(html => {
                this.panelTarget.innerHTML = html
            })
    }

    closeWithKeyboard(e) {
        if (e.keyCode === 27) {
            this.close(e)
        }
    }
}
