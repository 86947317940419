import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
    static targets = ["scope","selectedscope", "method", 'count']

    connect() {

        this.setScope();
        this.setCount();
    }

    setScope() {
        if (this.hasSelectedscopeTarget) {
        this.selectedscopeTarget.innerText = this.scopeTarget.selectedOptions[0].innerText }

    }

    setCount() {
        if (this.hasCountTarget) {
            var selectedCount = 0;
            this.methodTargets.forEach((element, index) => {
                if (element.checked) {
                    selectedCount++;
                }
            })
            this.countTarget.innerText = selectedCount;
        }
    }
}