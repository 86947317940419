import { Controller } from "@hotwired/stimulus"
import {useIntersection} from 'stimulus-use'
export default class extends Controller {
    connect() {
        useIntersection(this)
    }
    appear(entry) {
        console.log('last_message fired')
        const readLastMessageEvent = new CustomEvent('readLastMessage');
        window.dispatchEvent(readLastMessageEvent)
    }


}