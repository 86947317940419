import { Controller } from "stimulus";

import consumer from "../channels/consumer"

export default class extends Controller {
    static targets = ["unread"];


    connect() {

        this.subscription = consumer.subscriptions.create(
            {
                channel: "NotificationCountChannel",
                id: this.data.get("id"),
            },
            {
                connected: this._connected.bind(this),
                disconnected: this._disconnected.bind(this),
                received: this._received.bind(this)
            }
        );

    }

    _connected() {

    }

    _disconnected() {

    }

    _received(data) {
        this.unreadTarget.classList.add('animate-ping');
        this.unreadTarget.innerHTML=data.data;
        setTimeout(() => this.unreadTarget.classList.remove('animate-ping'), 1000)

    }

}