import { Controller } from "@hotwired/stimulus";
import {enter, leave} from 'el-transition';

export default class extends Controller {

    static targets = ["panel"]

    connect() {

        this.element[this.identifier] = this
        this.close();
    }


    close() {
        leave(this.panelTarget);
    }

    expand() {

        enter(this.panelTarget);
    }

}