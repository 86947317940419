// Entry point for the build script in your package.json

import {Turbo} from "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import Trix from 'trix'

import "./controllers";


import ChartDataLabels from 'chartjs-plugin-datalabels';
Turbo.start();
ActiveStorage.start();




function toggle_mobile_menu(event) {
    event.preventDefault();

}

document.addEventListener('turbolinks:load', function() {
    flatpickr(".flatpickr-input");
});

