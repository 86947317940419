import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["select", "custom"]
    static values = {
        options: Array,
        current: String
    }

    connect() {
        this.initializeSelection()
    }

    initializeSelection() {
        const currentValue = this.currentValue
        const options = this.optionsValue

        if (!currentValue || currentValue.trim() === '') {
            // Set to first option if current value is blank or nil
            this.selectTarget.value = options[0] || ''
            this.customTarget.classList.add("hidden")
            this.updateInterviewerValue()
        } else if (options.includes(currentValue)) {
            // Current value is in the list
            this.selectTarget.value = currentValue
            this.customTarget.classList.add("hidden")
        } else {
            // Current value is not in the list
            this.selectTarget.value = "Someone else"
            this.customTarget.classList.remove("hidden")
            this.customTarget.value = currentValue
        }
    }

    selectChanged() {
        const selectedValue = this.selectTarget.value

        if (selectedValue === "Someone else") {
            this.customTarget.classList.remove("hidden")
            this.customTarget.value = this.currentValue !== "Someone else" ? this.currentValue : ""
        } else {
            this.customTarget.classList.add("hidden")
            this.customTarget.value = selectedValue
        }

        // Update the form's interviewer value
        this.updateInterviewerValue()
    }

    updateInterviewerValue() {
        const selectedValue = this.selectTarget.value
        const formInterviewer = this.element.querySelector('input[name="interview[interviewer]"]')

        if (formInterviewer) {
            if (selectedValue === "Someone else") {
                formInterviewer.value = this.customTarget.value
            } else {
                formInterviewer.value = selectedValue
            }
        }
    }
}