import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {


    }

    all_selected() {
        console.log(document.querySelectorAll("input[name='new[authority_type]']:checked").length + document.querySelectorAll("input[name='new[payment_method]']:checked").length +  document.querySelectorAll("input[name='new[care_focus]']:checked").length)
        if ((document.querySelectorAll("input[name='new[authority_type]']:checked").length + document.querySelectorAll("input[name='new[payment_method]']:checked").length +  document.querySelectorAll("input[name='new[care_focus]']:checked").length)==3) {
            document.getElementById('subscription_form').submit();
        }
    }
}