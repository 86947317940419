import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['selected']
    connect() {
        // console.log('connected')

    }

    setDay(e) {
        e.preventDefault()
        let  el = e.target
        let targets = document.querySelectorAll("[data-day='"+el.dataset.day+"']");
        let selected = 0;
        for (let i=1; i<6; i++ ) {
            if  (targets[i].checked) {
                selected++;
            }
        }
        if (selected===5) {
            for (let i=1; i<6; i++ ) {
               targets[i].checked = false
            }

        } else {
            for (let i=1; i<6; i++ ) {
                targets[i].checked = true
            }
        }

    }

    setTime(e) {
        e.preventDefault()
        let  el = e.target
        let targets = document.querySelectorAll("[data-time='"+el.dataset.time+"']");
        let selected = 0;
        for (let i=1; i<8; i++ ) {
            if  (targets[i].checked) {
                selected++;
            }
        }
        if (selected===7) {
            for (let i=1; i<8; i++ ) {
                targets[i].checked = false
            }

        } else {
            for (let i=1; i<8; i++ ) {
                targets[i].checked = true
            }
        }

    }

    availabilitySelected() {

        let targets = document.querySelectorAll("[data-time='"+el.dataset.time+"']");
    }

}