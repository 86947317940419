import {Controller} from 'stimulus'

export default class extends Controller {

    static values = {
        current: Number,
    }

    static targets = ['star'];

    connect() {
       // console.log(this.currentValue)
       this.setCurrent();


    }

    whichStar(event) {

        for (let i = 0; i <= event.currentTarget.dataset.starRatingIndex-1; i++) {
            this.starTargets[i].classList.add('bg-blue-500')
        }

    }

    reset(event) {
        for (let i = 0; i <= 4; i++) {
            this.starTargets[i].classList.remove('bg-blue-500')
        }
        this.setCurrent();
    }

    setCurrent() {
        for (let i = 0; i <= this.currentValue-1; i++) {
            this.starTargets[i].classList.add('bg-blue-500')
        }
    }
}