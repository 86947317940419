import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['wrapper', 'container', 'content', 'background']

    initialize() {
        this.url = this.data.get('url')
    }

    view(e) {
        console.log('clicked');
        if (e.target !== this.wrapperTarget &&
            !this.wrapperTarget.contains(e.target)) return

        if (this.open) {
            this.getContent(this.url)
            this.wrapperTarget.insertAdjacentHTML('afterbegin', this.template())
        }
    }

    close(e) {
        e.preventDefault()

        if (this.open) {
            if (this.hasContainerTarget) {
                this.containerTarget.remove()
            }
        }
    }

    closeBackground(e) {
        if (e.target === this.backgroundTarget) {
            this.close(e)
        }
    }

    closeWithKeyboard(e) {
        if (e.keyCode === 27) {
            this.close(e)
        }
    }

    getContent(url) {
        fetch(url).then(response => {
            if (response.ok) {
                return response.text()
            }
        })
            .then(html => {
                this.contentTarget.innerHTML = html
            })
    }

    template() {
        return `
      <div data-remote-target='container'>
        <div class='modal-wrapper' data-remote-target='background' data-action='click->remote#closeBackground'>
          <div class='fixed top-0 w-full bg-white rounded z-20 overflow-auto shadow-xl' data-remote-target='content'>
          <span class='loader-spinner m-2'></span>
        </div>

        <button data-action='click->remote#close' class='absolute top-0 right-0 w-6 h-6 m-1 text-white z-20 m-2'>
          <svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <path d='M6 18L18 6M6 6L18 18' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
          </svg>
        </button>
        </div>
      </div>
    `
    }
}