import { Controller } from "@hotwired/stimulus";
import elementBoundEffect from "alpinejs";

export default class extends Controller {
    static targets = ["option"];


    connect() {

         // this.optionTargets.forEach((element, index) => {
         //     console.log(index)
        // })
      this.update();
    }

    update()  {
        this.optionTargets.forEach((element, index) => {
            if (element.children[0].children[1].children[0].checked) {
                element.classList.add('border-brand-500')
                element.children[0].children[0].children[0].children[0].classList.add('font-bold')
            }
            else {
                element.classList.remove('border-brand-500')
                element.children[0].children[0].children[0].children[0].classList.remove('font-bold')
            }
        })
    }

    check(e)  {

    }
}