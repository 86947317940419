import { Controller } from "stimulus"

export default class extends Controller {
    reset() {

        this.element.reset()
        this.element.blur()
        this.element.focus()

    }
}
