import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["button", "password", "confirmation", "letter", "space", "capital", "number", "length", "match"]


    connect() {
        //    console.log('password connected')
        this.buttonTarget.disabled = true;
    }

    setCondition(el, state) {
        if (state) {
            el.classList.remove('text-red-600')
            el.classList.add('text-green-600');

        } else {
            el.classList.add('text-red-600')
            el.classList.remove('text-green-600');

        }
    }

    check() {


        //validate the length

        this.setCondition(this.lengthTarget, (this.passwordTarget.value.length > 7))
        this.setCondition(this.letterTarget, (this.passwordTarget.value.match(/[A-z]/)))
        this.setCondition(this.capitalTarget, (this.passwordTarget.value.match(/[A-Z]/)))
        this.setCondition(this.numberTarget, (this.passwordTarget.value.match(/\d/)))
        this.setCondition(this.spaceTarget, (this.passwordTarget.value.match(/[^a-zA-Z0-9\-\/]/)))
        this.setCondition(this.matchTarget, (this.passwordTarget.value == this.confirmationTarget.value))


        if (
            (this.passwordTarget.value.length > 7) && (this.passwordTarget.value.match(/[A-z]/)) && (this.passwordTarget.value.match(/[A-Z]/)) && (this.passwordTarget.value.match(/[A-Z]/)) && (this.passwordTarget.value.match(/\d/)) && (this.passwordTarget.value.match(/[^a-zA-Z0-9\-\/]/)) && (this.passwordTarget.value == this.confirmationTarget.value)) {
            this.buttonTarget.disabled = false;
        } else {
            this.buttonTarget.disabled = true;
        }

    }


}