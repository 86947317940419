import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.toggleAccountManager()
    }

    toggleAccountManager() {

        if (document.getElementById('am_type').value == "service_user") {

            document.getElementById('am_first_name').value = "";
            document.getElementById('am_last_name').value = "";
            document.getElementById('am_first_name').classList.add('hidden');
            document.getElementById('am_last_name').classList.add('hidden');
            document.getElementById('label_am_first_name').classList.add('hidden');
            document.getElementById('label_am_last_name').classList.add('hidden');

        } else {

            document.getElementById('am_first_name').classList.remove('hidden');
            document.getElementById('am_last_name').classList.remove('hidden');
            document.getElementById('label_am_first_name').classList.remove('hidden');
            document.getElementById('label_am_last_name').classList.remove('hidden');
            if (document.getElementById('am_type').value == this.current_relationship) {
                document.getElementById('am_first_name').value = this.current_first_name;
                document.getElementById('am_last_name').value = this.current_last_name;
            }
        }
    }


     get current_relationship() {
       return this.element.dataset.current_relationship
     }
    get current_first_name() {
        return this.element.dataset.current_first_name
    }
    get current_last_name() {
        return this.element.dataset.current_last_name
    }


}