import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['label','slider'];

    labels = [
        "Haven't done it before",
        "Up to a year",
        "2 years",
        "3 years",
        "4 years",
        "5 years and over"
    ];

    connect() {


        this.updateLabel(); // Initialize the label when the controller connects

    }
    update() {
        this.updateLabel();
    }
    updateLabel() {
        const sliderValue = parseInt(this.sliderTarget.value, 10);
        this.labelTarget.textContent = this.labels[sliderValue];
    }
}