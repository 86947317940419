import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['pay', 'hours', 'titles', 'title']

    connect() {

        this.updateSuggestedTitles();
    }


    updateSuggestedTitles() {

        this.titlesTarget.innerHTML = this.htmListOfTitles();
    }

    htmListOfTitles() {
        const titles = this.suggestedTitles()
        let list = ''
        titles.forEach((title, index) => {
            let html = `
            <li class="my-2">
                <p class="text-brand hover:underline" data-action="click->name-suggestion#setTitle">${title}</p>
            </li>
            `
            list += html;
        })


        return list
    }

    setTitle(event) {
        this.titleTarget.value = event.target.innerHTML
        const titleSetEvent = new CustomEvent('titleSet');
        window.dispatchEvent(titleSetEvent)

    }

    suggestedTitles() {

        const pay = Number(this.payTarget.value);
        const hours = Number(this.hoursTarget.value);
        const gender = this.element.dataset.gender;
        const postcode = this.element.dataset.outcode;
        const town = this.element.dataset.town;

        const age = Number(this.element.dataset.serviceUserAge);
        const personal = document.getElementById('vacancy_skills_personalcare').checked;
        const autism = document.getElementById('vacancy_skills_autism').checked;
        const dementia = document.getElementById('vacancy_skills_dementiacare').checked;
        const fte = Number(document.getElementById('annualsalary').innerHTML.replace(/[^\d.-]/g, ''));
        const monthly = Number(document.getElementById('ptmonthly').innerHTML.replace(/[^\d.-]/g, ''));
        const weekly = Number(document.getElementById('ptweekly').innerHTML.replace(/[^\d.-]/g, ''));

        let age_noun = ''
        let gender_noun = ''
        let hours_noun = ''
        let pay_noun = ''
        let care_type = ''
        if (age <= 11) {
            age_noun = 'young';
            if (gender === 'Male') {
                gender_noun = 'boy'
            } else if (gender === 'Female') {
                gender_noun = 'girl'
            } else {
                gender_noun = 'person'
            }
            ;
        } else if (age <= 18) {
            age_noun = 'teenage';
            if (gender === 'Male') {
                gender_noun = 'boy'
            } else if (gender === 'Female') {
                gender_noun = 'girl'
            } else {
                gender_noun = ''
            }
            ;
        } else if (age <= 30) {
            age_noun = 'young';
            if (gender === 'Male') {
                gender_noun = 'man'
            } else if (gender === 'Female') {
                gender_noun = 'woman'
            } else {
                gender_noun = 'person'
            }
            ;
        } else if (age <= 50) {
            if (gender === 'Male') {
                gender_noun = 'gentleman'
            } else if (gender === 'Female') {
                gender_noun = 'lady'
            } else {
                gender_noun = 'person'
            }
            ;
        } else if (age <= 120) {
            if (gender === 'Male') {
                gender_noun = 'senior gentleman'
            } else if (gender === 'Female') {
                gender_noun = 'senior lady'
            } else {
                gender_noun = 'person'
            }
            ;
        } else {
            age_noun = '';
            gender_noun = '';
        }
        ;

        if (hours <= 16) {
            hours_noun = 'P/T'

        } else if (hours <= 35) {
            hours_noun = String(hours) + ' hr/wk'
        } else {
            hours_noun = 'F/T'
        }
        ;

        if (pay <= 9) {
            pay_noun = 'low'

        } else if (hours <= 12) {
            pay_noun = 'med'
        } else {
            pay_noun = 'High'
        }
        ;

        if (personal === true) {
            care_type = "Personal "
        } else if (dementia === true) {
            care_type = "Dementia "
        } else if (autism === true) {
            care_type = "Autism "
        } else {
            care_type = ""
        }
        ;


        let suggestion1 = care_type + 'Care Assistant for a ' + age_noun + ' ' + gender_noun + ' ' + hours_noun + '@ £' + pay.toFixed(2) + '/hr in ' + town + ' - FTE - £' + (fte / 1000).toFixed(1) + 'k';
        let suggestion2 = care_type + 'Care Assistant for a ' + age_noun + ' ' + gender_noun + ' ' + hours_noun + '@ £' + pay.toFixed(2) + '/hr in ' + postcode + ' - FTE - £' + (fte / 1000).toFixed(1) + 'k';
        let suggestion3 = care_type + 'Care Assistant for a ' + age_noun + ' ' + gender_noun + ' ' + hours_noun + '@ £' + weekly.toFixed(0) + '/weekly in ' + postcode + ' - FTE - £' + (fte / 1000).toFixed(1) + 'k';
        return [suggestion1, suggestion2, suggestion3];

    }

}