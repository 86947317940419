import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "value", "button", "switch" ]

    connect() {
        //console.log('toggle Switch Controller connected')
        if (this.valueTarget.value==0) {
            this.toggleOff();
        }
        else {
            this.toggleOn();
        }
    }

    submit() {
        if (this.valueTarget.value==0) {
            this.valueTarget.value=1
        }
        else {
            this.valueTarget.value=0
        }
        toggle();
       // console.log('submit')
    }

    toggleOn() {

            this.buttonTarget.classList.add("bg-brand-500");
            this.buttonTarget.classList.remove("bg-gray-200");
            this.switchTarget.classList.add("translate-x-8");
            this.switchTarget.classList.remove("translate-x-0");

    }

    toggleOff() {

        this.buttonTarget.classList.remove("bg-brand-500");
        this.buttonTarget.classList.add("bg-gray-200");
        this.switchTarget.classList.remove("translate-x-8");
        this.switchTarget.classList.add("translate-x-0");

    }

}