import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["card"];
    static values = { url: String };
    connect() {

    }

    show() {

        if (this.hasCardTarget) {
            this.cardTarget.classList.remove("hidden");
        } else {
            fetch(this.urlValue)
                .then((r) => r.text())
                .then((html) => {
                    const fragment = document
                        .createRange()
                        .createContextualFragment(html);

                    this.element.appendChild(fragment);
                });
        }
    }

    hide() {
        if (this.hasCardTarget) {
            this.cardTarget.classList.add("hidden");
        }
    }

    disconnect() {
        if (this.hasCardTarget) {
            this.cardTarget.remove();
        }
    }

}