import { Controller } from "stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
    static targets = ["status"];

    connect() {
        this.slug = this.data.get("slug"); // Get the slug from data attributes

        this.subscription = consumer.subscriptions.create(
            { channel: "UserChannel" },
            {
                received: this.received.bind(this),
            }
        );
    }

    disconnect() {
        if (this.subscription) {
            consumer.subscriptions.remove(this.subscription);
            this.subscription = null;
        }
    }

    sendStatus(status) {
        if (this.subscription) {
            this.subscription.send({ slug: this.slug, status });
        }
    }

    online() {
        this.sendStatus("online");
    }

    away() {
        this.sendStatus("away");
    }

    offline() {
        this.sendStatus("offline");
    }

    received(data) {
        // Update the status only if the message is for this user
        if (data.slug === this.slug) {
            this.updateStatus(data.status);
        }
    }

    updateStatus(status) {
        if (this.hasStatusTarget) {
            this.statusTarget.className = status; // Update class dynamically
        }
    }
}
