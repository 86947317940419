import { Toggle } from "tailwindcss-stimulus-components"

export default class ListToggle extends Toggle {

    connect() {
        document.addEventListener('turbo:load', () => {
            this.toggleableTarget.classList.toggle('hidden');
        });

      //  super.connect();
    }

    toggle() {
        //    console.log('toggle')
        //super.toggle();

        this.toggleableTarget.classList.toggle('scale-y-0');
        this.toggleableTarget.classList.toggle('scale-y-screen');
        this.toggleableTarget.classList.toggle('h-0');
        this.toggleableTarget.classList.toggle('h-auto');
    }


}