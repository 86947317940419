// radio_button_controller.js
import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    connect() {


    }

    setInitialState() {
        this.element.querySelectorAll('input[type="radio"]').forEach(((input, index) => {
            this.updateLabel(input);

        }));
    }

    toggleActive(event) {
        this.element.querySelectorAll('input[type="radio"]').forEach(((input, index) => {
            this.updateLabel(input);
            this.updateSvg(input, this.iconTargets[index])
        }));
    }

    updateLabel(input) {
        const label = input.closest('label');
        if (input.checked) {
            label.classList.add('border-brand-600', 'ring-2', 'ring-brand-600');
            label.classList.remove('border-gray-300');
            this.sendEvent(input)

        } else {
            label.classList.remove('border-brand-600', 'ring-2', 'ring-brand-600');
            label.classList.add('border-gray-300');

        }
    }



    sendEvent(input) {
        // Create a custom event with additional data
        const event = new CustomEvent("radioButtonSelected", {
            bubbles: true, // Allows the event to bubble up through the DOM
            detail: input  // Custom data passed with the event
        });

        // Dispatch the event from the controller's element
        this.element.dispatchEvent(event);
    }



}
