import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets= ['selected']
    connect() {
      //   console.log('connected')
        this.setGender();
    }
     setGender() {
   //      console.log('changed')
        var currentValue = this.element.dataset.currentGender;
         var setValue = this.selectedTarget.value;
    //     console.log('Current:' + currentValue);
    //     console.log('Set:' + setValue);

        if ((setValue==currentValue && setValue=="") || (setValue=='Male') || (setValue=='Female') || (setValue=='Non-Binary' ) || (setValue=='Prefer not to say') || (setValue==currentValue) ) {
            document.getElementById('gender').classList.add('hidden');
            document.getElementById('gender').value=setValue;
        }
        else {
            document.getElementById('gender').value
            document.getElementById('gender').classList.remove('hidden');
            document.getElementById('gender').value="";
            document.getElementById('gender').placeholder="What's your gender identity?";
            document.getElementById('gender').focus();

        }
     }

}