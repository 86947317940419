import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

    static targets = ["detail", "pcdetail"];
    static values = {detail: String, pcdetail: String};

    connect() {
        // console.log('dbs connected')
        if (this.detailValue === 'true') {
            this.show(this.detailTarget)
        } else
            this.hide(this.detailTarget)


        this.pcdetailTargets.forEach((element) => {
            if (this.pcdetailValue === 'true') {
                this.show(element)
            } else {
                this.hide(element)
            }
        })

        document.addEventListener('DOMContentLoaded', function () {
            document.getElementById('dbs_certificate_file').addEventListener('change', function (event) {
                var fileName = event.target.files[0].name;
                document.getElementById('file-name').textContent = fileName; // Display the selected file name
            });

            document.querySelector('.button-outline').addEventListener('click', function (event) {
                event.preventDefault(); // Prevent form submission
                document.getElementById('dbs_certificate_file').click(); // Trigger file input click
            });
        });

    }

    showDetail(event) {
        
        if (event.target.value === 'true') {
            this.show(this.detailTarget)
        } else
            this.hide(this.detailTarget)
    }

    showpcDetail(event) {
        this.pcdetailTargets.forEach((element) => {
            if (event.target.value === 'true') {
                this.show(element);
            } else

                this.hide(element)
        })
    }

    show(target) {


        target.classList.remove('h-0', 'scale-y-0');
        target.classList.add('px-4', 'py-5', 'sm:rounded-lg', 'sm:p-6', 'my-4', 'h-auto', 'scale-y-screen');

    }

    hide(target) {
        target.classList.add('h-0', 'scale-y-0');
        target.classList.remove('px-4', 'py-5', 'sm:rounded-lg', 'sm:p-6', 'my-4','h-auto','scale-y-screen');

    }

}