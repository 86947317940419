import {Controller} from "@hotwired/stimulus";
import Trix from 'trix'

export default class extends Controller {

    static targets = ["editor"]
    static values = {
        goalWords: {type: Number, default: -1},
        wordCount: {type: Boolean, default: true},
        lines: {type: String, default: 'lg'},
        minWords: {type: Number, default: 100000000 }
    }

    connect() {
        //  console.log(this.element.getAttribute('toolbar'))
        this.initializeTrixEditor();

        if (this.wordCountValue) {
            this.initializeWordCount();

                this.toggleSubmitButton(this.wordCountValue);

        }


        this.editorTarget.classList.add("trix-min-height-"+this.linesValue);

    }


    initializeTrixEditor() {
        const UNUSED_TOOLBAR_CLASSES = [
            ".trix-button--icon-strike",
            ".trix-button--icon-link",
            //".trix-button--icon-heading-1",
            ".trix-button--icon-quote",
            ".trix-button--icon-code",
            // ".trix-button--icon-decrease-nesting-level",
            // ".trix-button--icon-increase-nesting-level",
            ".trix-button-group--file-tools",

        ];

        const BEHAVIOUR = '';

        const BOLD_SVG =
            '<svg viewBox="0 0 18 18"> <path class="ql-stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path> <path class="ql-stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path> </svg>';
        const ITALIC_SVG =
            '<svg viewBox="0 0 18 18"> <line class="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line> <line class="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line> <line class="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line> </svg>';
        const UNDERLINE_SVG =
            '<svg  viewBox="0 0 18 18"> <path class="ql-stroke" d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path> <rect class="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect> </svg>';
        const BULLET_LIST_SVG =
            '<svg viewBox="0 0 18 18"> <line class="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> <line class="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> <line class="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> <line class="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> <line class="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> <line class="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> </svg>';
        const NUMBER_LIST_SVG =
            '<svg viewBox="0 0 18 18"> <line class="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> <line class="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> <line class="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> <line class="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> <path class="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> <path class="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> <path class="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> </svg>';

        const UNDO_SVG = '<svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M447.9,368.2c0-16.8,3.6-83.1-48.7-135.7c-35.2-35.4-80.3-53.4-143.3-56.2V96L64,224l192,128v-79.8   c40,1.1,62.4,9.1,86.7,20c30.9,13.8,55.3,44,75.8,76.6l19.2,31.2H448C448,389.9,447.9,377.1,447.9,368.2z"/></g></svg>';// '&#10226;';

        const REDO_SVG = '<svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512"   xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M64,400h10.3l19.2-31.2c20.5-32.7,44.9-62.8,75.8-76.6c24.4-10.9,46.7-18.9,86.7-20V352l192-128L256,96v80.3   c-63,2.8-108.1,20.7-143.3,56.2c-52.3,52.7-48.7,119-48.7,135.7C64.1,377.1,64,389.9,64,400z"/></g></svg>';//'&#10227;';

        const HEADING_SVG = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none"> <path class="ql-fill" d="M0 5V1H10V5H8V3H6V13H8V15H2V13H4V3H2V5H0Z" "/><path d="M8 7H16V9H13V15H11V9H8V7Z" class="ql-fill"/></svg>';

        const INDENT_PLUS_SVG = '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" x1="112" x2="216" y1="128" y2="128"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" x1="112" x2="216" y1="64" y2="64"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" x1="40" x2="216" y1="192" y2="192"/><polyline fill="none" points="40 56 80 96 40 136" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/></svg>';
        const INDENT_MINUS_SVG = '<svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><rect fill="none" height="256" width="256"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" x1="112" x2="216" y1="128" y2="128"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" x1="112" x2="216" y1="64" y2="64"/><line fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16" x1="40" x2="216" y1="192" y2="192"/><polyline fill="none" points="72 56 32 96 72 136" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"/></svg>';

        const TOOLBAR_BUTTON_ICONS = [
            {
                identifier: ".trix-button--icon-heading-1",
                icon: HEADING_SVG
            },
            {
                identifier: ".trix-button--icon-bold",
                icon: BOLD_SVG
            },
            {
                identifier: ".trix-button--icon-italic",
                icon: ITALIC_SVG
            },
            {
                identifier: ".trix-button--icon-underline",
                icon: UNDERLINE_SVG
            },
            {
                identifier: ".trix-button--icon-bullet-list",
                icon: BULLET_LIST_SVG
            },
            {
                identifier: ".trix-button--icon-number-list",
                icon: NUMBER_LIST_SVG
            },
            {
                identifier: ".trix-button--icon-undo",
                icon: UNDO_SVG
            },
            {
                identifier: ".trix-button--icon-redo",
                icon: REDO_SVG
            },
            {
                identifier: ".trix-button--icon-decrease-nesting-level",
                icon: INDENT_MINUS_SVG
            },
            {
                identifier: ".trix-button--icon-increase-nesting-level",
                icon: INDENT_PLUS_SVG
            },

        ];


        // Remove unused toolbar buttons
        UNUSED_TOOLBAR_CLASSES.forEach((cls) => {
            document.querySelectorAll(cls).forEach((el) => el.remove());
        });

        // Update toolbar icons
        TOOLBAR_BUTTON_ICONS.forEach((group) => {
            document.querySelectorAll(group.identifier).forEach((el) => {
                el.innerHTML = group.icon;
            });
        });
        this.editorTarget.classList.add('h-['+this.linesValue+'em]')
    }

    initializeWordCount() {
        // Create a word count element
        const wordCountElement = document.createElement("span")
        wordCountElement.className = "trix-word-count"
        wordCountElement.textContent = "0"

        // Append the word count element to the Trix toolbar
        const toolbarElement = document.getElementById(this.element.getAttribute('toolbar'))

//.element.getAttribute('toolbar')

        toolbarElement.style.position = "relative"
        toolbarElement.appendChild(wordCountElement)

        // Update word count on text change
        this.editorTarget.addEventListener("trix-change", (event) => {
            const wordCount = this.calculateWordCount(event.target.value)
            wordCountElement.textContent = `${wordCount}`
            this.statusColor(wordCountElement, wordCount);
            this.toggleSubmitButton(wordCount);
        })

        const wordCount = this.calculateWordCount(this.element.value)
        wordCountElement.textContent = `${wordCount}`
        this.statusColor(wordCountElement, wordCount);
        this.toggleSubmitButton(wordCount);
    }

    calculateWordCount(text) {
        return text.trim().split(/\s+/).filter((word) => word.length > 0).length
    }

    statusColor(wordCountElement, count) {
        // Array of possible classes
        const colorClasses = ["text-black", "text-red-500", "text-amber-500", "text-green-500"];

        // Remove all possible color classes from the element
        wordCountElement.classList.remove(...colorClasses);

        // Calculate the ratio of count to goalWordsValue
        const ratio = count / this.goalWordsValue;

        // Determine the appropriate class based on the ratio and add it to the element
        let colorClass;
        if (count < 0) {
            colorClass = "text-black"; // Negative count returns black
        } else if (ratio < 0.5) {
            colorClass = "text-red-500";   // Less than 50% returns red
        } else if (ratio < 0.75) {
            colorClass = "text-amber-500"; // Less than 75% returns amber
        } else {
            colorClass = "text-green-500"; // Any other positive value returns green
        }

        // Add the selected color class to the element
        wordCountElement.classList.add(colorClass);
    }

    toggleSubmitButton(count) {
        // // Get the submit button by its ID or class outside the controller's scope
        // const submitButton = document.getElementById('submit-button-id'); // Replace with the correct ID
        //
        // if (submitButton) {
        //     submitButton.disabled = count < this.minWordsValue;
        // }
    }
}
