import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["menu", "pinstatus", "hidebar"]
    static values = { isopen: Boolean, ispinned: Boolean }

    connect() {
        // console.log(this.isopenValue)
        // console.log(this.ispinnedValue)
        // console.log(this.pinstatusTarget.text)
        if  (this.ispinnedValue) {
            this.pinstatusTarget.value = 'Pinned';
            this.desktopShow()
            this.menuTarget.classList.toggle("-translate-x-full");
        }
        else
         {
             this.pinstatusTarget.value = 'unpinned';
             this.desktopHide()
        }
    }

    mobileToggle() {
        this.menuTarget.classList.toggle("-translate-x-full");
    }

    desktopHide() {
        if (this.ispinnedValue == false) {

            this.menuTarget.classList.remove("md:relative");
            this.menuTarget.classList.remove("md:translate-x-0");
            this.menuTarget.classList.add("-translate-x-full");
            this.isopenValue = false;
            this.hidebarTarget.classList.remove("hidden")
        }
    }

    desktopShow() {

            this.menuTarget.classList.add("md:relative");
            this.menuTarget.classList.add("md:translate-x-0");
            this.menuTarget.classList.remove("-translate-x-full");
            this.hidebarTarget.classList.add("hidden")

    }

    pinnedToggle(){
        this.ispinnedValue = !this.ispinnedValue
          if (this.ispinnedValue) {
              this.pinstatusTarget.innerHTML = '<i class="las la-chevron-circle-right text-icon-md text-brand-500"></i>';
              this.desktopShow();
          }
          else {
              console.log('unpinned');
              this.pinstatusTarget.innerHTML = '<i class="las la-chevron-circle-left text-icon-md text-brand-500"></i>';
              this.desktopHide();
          }


        }


}