import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['pay', 'hours', 'fte', 'actual', 'monthly', 'weekly', 'payd', 'paydw']

    connect() {
        this.calculateSummary()
    }

    calculateSummary() {

        this.fteTarget.innerHTML = (this.payTarget.value * 52 * 37.5).toLocaleString('en', {
            style: 'currency',
            currency: 'GBP'
        })
        this.actualTarget.innerHTML = (this.payTarget.value * 52 * this.hoursTarget.value).toLocaleString('en', {
            style: 'currency',
            currency: 'GBP'
        })
        this.monthlyTarget.innerHTML = (this.payTarget.value * 52 * this.hoursTarget.value / 12).toLocaleString('en', {
            style: 'currency',
            currency: 'GBP'
        })
        this.weeklyTarget.innerHTML = (this.payTarget.value * this.hoursTarget.value).toLocaleString('en', {
            style: 'currency',
            currency: 'GBP'
        })

        if (this.hasPaydTarget) {
            if (((this.payTarget.value - this.element.dataset.avgPay) / this.element.dataset.avgPay * 100) < 0) {
                this.paydTarget.innerText = ((this.payTarget.value - this.element.dataset.avgPay) / this.element.dataset.avgPay * -100).toFixed(1) + '% lower';
            } else {
                this.paydTarget.innerText = ((this.payTarget.value - this.element.dataset.avgPay) / this.element.dataset.avgPay * 100).toFixed(1) + '% higher';
            }

            if ((((this.payTarget.value * this.hoursTarget.value) - this.element.dataset.avgWeeklyPay) / this.element.dataset.avgWeeklyPay * 100) < 0) {
                this.paydwTarget.innerText = (((this.payTarget.value * this.hoursTarget.value) - this.element.dataset.avgWeeklyPay) / this.element.dataset.avgWeeklyPay * 100).toFixed(1) + '% lower';
            } else {
                this.paydwTarget.innerText = (((this.payTarget.value * this.hoursTarget.value) - this.element.dataset.avgWeeklyPay) / this.element.dataset.avgWeeklyPay * 100).toFixed(1) + '% higher';
            }
        }
    }

}